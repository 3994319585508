.job-entry {
    margin-bottom: 3rem;
    /* Increases space between job entries */
    padding-bottom: 4rem;
    /* Adds internal padding */
    border-bottom: 1px solid var(--text-color-2);
    /* Optional: Adds a subtle border */
}

.text-demoted {
    color: var(--text-color-2);
}

.job-entry h4 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem; /* Reduced margin between job title and company */
}

.job-entry .job-info {
    text-align: right;
}

.job-entry p {
    margin-bottom: 0.5rem;
}

.job-entry ul {
    list-style-type: disc;
    margin-left: 1.5rem;
}

.job-entry ul li {
    margin-bottom: 0.25rem;
}

footer {
    margin-top: 3rem;
    /* Adds space above the footer */
    padding-bottom: 2rem;
    /* Adds padding inside the footer */
}
