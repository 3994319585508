.blog-box {
    position: relative;
    padding: 30px;
    border-radius: 8px;
    border: 0.1rem solid transparent;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    width: 100%;
    height: auto; /* Match project height */
    overflow: hidden;
    z-index: 1;
    background-color: var(--bg-color); /* Ensure background matches current theme */
}

/* Before Hover: Transparent Overlay */
.blog-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color); /* Base background color */
    opacity: 0; /* Initially invisible */
    z-index: -1;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

/* Hover Effect: Background transitions based on text color, and opacity added */
.blog-box:hover::before {
    background-color: var(--text-color); /* The hover color is now based on text color */
    opacity: 0.1; /* Light transparency, background only */
}

.blog-box:hover {
    transform: translateY(-5px); /* Lifts box on hover */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-color: var(--text-color-1); /* Border appears on hover */
    color: var(--text-color-2); /* Text color also changes on hover */
}

.blog-link {
    text-decoration: none;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.3s ease;
}

.blog-link h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.blog-date {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.blog-arrow-icon {
    font-size: 1.5rem;
    margin-left: 5px;
    margin-bottom: 0.375rem;
    transition: transform 0.5s ease;
}

.icon-group {
    display: inline-flex;
    margin-left: 15px;
}

.platform-icon {
    margin-right: 5px;
    font-size: 1.5rem;
    margin-bottom: 0.375rem;
}

.sec_sp {
    margin-bottom: 4rem;
}

.blog-box:hover {
    border-width: 0.1rem; /* Thin border when hovered */
}
